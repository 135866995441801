import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from "@material-ui/core";

type Props = {
  title: string;
  contentBody?: React.ReactNode;
  contentText?: string;
  onCancel(): void;
  onSuccess(): void;
  ariaLabel: string;
  isDiaglogOpen: boolean;
  maxWidth?: "sm" | "md" | "lg";
};

export const Modal: React.FC<Props> = ({
  title,
  contentBody,
  contentText,
  onCancel,
  onSuccess,
  isDiaglogOpen,
  maxWidth,
  ariaLabel,
}) => {
  return (
    <Dialog
      open={isDiaglogOpen}
      aria-labelledby={`modal-dialog-for-${ariaLabel}`}
      data-testid={`${ariaLabel}-modal-dialog`}
      maxWidth={maxWidth ?? "md"}
      fullWidth={false}
    >
      <DialogTitle id={`${title}-dialog-title`}>{title}</DialogTitle>
      <DialogContent>
        {contentText && <DialogContentText id={`${title}-dialog-description`}>{contentText}</DialogContentText>}
        {contentBody}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onSuccess} color="primary">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};
