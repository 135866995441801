import React, { ReactElement, useMemo } from "react";
import { Measure, Step } from "utils/api";
import { useMeasures } from "hooks/useMeasures";
import { Box } from "@material-ui/core";
import styles from "./StepsEditor.module.scss";
import { ColumnAdder } from "./ColumnAdder";
import { ColumnRemover } from "./ColumnRemover";
import { buildEmptyStep, stepHasData } from "../utils";
import DataTable, { ColumnData } from "components/data-table/DataTable";

interface StepsEditorProps {
  steps: Array<Step>;
  measures: Set<string>;
  requiredMeasures: Set<string>;
  setIsDataTableValid?: (valid: boolean) => void;
  onChange: (newSteps: Array<Step>, newMeasures?: Array<string>) => void;
}

export function StepsEditor({
  steps,
  measures: measureIds,
  requiredMeasures,
  onChange,
  setIsDataTableValid,
}: StepsEditorProps): ReactElement {
  const [measures] = useMeasures();

  const unusedMeasures = useMemo<Array<Measure>>(() => {
    if (!measures) {
      return [];
    }
    return Array.from(measures.values()).filter((measure) => !measureIds.has(measure.id));
  }, [measures, measureIds]);

  if (!measures) {
    return <div>Something went wrong...</div>;
  }

  function handleColumnAdded(measureId: string) {
    const newSteps = steps.map((step) => ({
      ...step,
      [measureId]: null,
    }));
    const newMeasures = [...Array.from(measureIds.values()), measureId];
    onChange(newSteps, newMeasures);
  }

  function handleColumnRemoved(measureId: string) {
    const newSteps = steps.map((step) => {
      const newStep = { ...step };
      delete newStep[measureId];
      return newStep;
    });
    const newMeasuresSet = new Set(measureIds);
    newMeasuresSet.delete(measureId);
    onChange(newSteps, Array.from(newMeasuresSet));
  }

  function handleStepChange(newSteps: Array<Step>) {
    if (newSteps.length < 1 || stepHasData(newSteps[newSteps.length - 1])) {
      onChange([...newSteps, buildEmptyStep(Array.from(measureIds.values()))]);
    } else {
      onChange(newSteps);
    }
  }

  const RemovableColumnHeader = (columnData: ColumnData) => {
    const measure = measures.get(columnData.id);
    const headerText = <span className={styles.columnHeaderText}>{columnData.name}</span>;
    if (measure) {
      return (
        <Box className={styles.headerBox}>
          <div>{headerText}</div>
          <ColumnRemover measure={measure} steps={steps} onRemoved={handleColumnRemoved} />
        </Box>
      );
    } else {
      return headerText;
    }
  };

  const columns = Array.from(measureIds.values())
    .map((measureId) => measures.get(measureId))
    .filter((measure): measure is Measure => measure !== undefined)
    .map((measure) => {
      const id = measure.id;
      const name = measure.units ? `${measure.label} (${measure.units})` : measure.label;
      const headerRenderer = requiredMeasures.has(measure.id) ? undefined : RemovableColumnHeader;
      return {
        id,
        name,
        headerRenderer,
        editable: true,
      };
    });

  return (
    <Box className={styles.editStepsContainer}>
      <div className={styles.tableContainer}>
        <DataTable
          rows={steps}
          columns={columns}
          sortEnabled={false}
          onUpdate={handleStepChange}
          setIsDataTableValid={setIsDataTableValid}
        />
      </div>
      <ColumnAdder options={unusedMeasures} onAdded={handleColumnAdded} />
    </Box>
  );
}
