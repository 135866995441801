import { useCallback } from "react";
import _ from "lodash";

const useValidator = () => {
  // explanation for the regex:
  // ^ - matches the start of the string
  // [+-]? - matches an optional plus or minus sign (+ or -)
  // \d+ - matches one or more digits
  // (\.\d+)? - matches an optional decimal point (.), followed by one or more digits
  // $ - matches the end of the string
  const numberRegex = /^[+-]?\d+(\.\d+)?$/;

  const containsNumber = (value: string | null | undefined): value is string =>
    Boolean(value && numberRegex.test(value));

  const validateDataCell = useCallback((value: string | null | undefined) => {
    // we're checking to see if the value is numeric, and it doesnt have any non-numeric characters
    // any further validation can be added as required
    return _.isEmpty(value) ? true : containsNumber(value);
  }, []);

  return [validateDataCell];
};

export default useValidator;
