import React, { createContext } from "react";
import { Box } from "@material-ui/core";
import AccessDeniedPage from "./access-denied-page/AccessDeniedPage";
import Throbber from "../throbber/Throbber";
import styles from "./auth-wrapper.module.scss";
import { useAuth } from "./useAuth";

interface AuthContextType {
  email: string | null;
  hasConsented: boolean;
  logout?: () => void;
}

export const AuthContext = createContext<AuthContextType>({ email: null, hasConsented: false });

export const AuthProvider: React.FC = ({ children }) => {
  const { loading, authenticated, authorised, error, logout, email, hasConsented } = useAuth();

  // TODO handle errors
  if (loading || error || !authenticated) {
    return (
      <Box className={styles["auth-wrapper"]}>
        <Throbber message="Loading..." />
      </Box>
    );
  }

  if (!authorised) {
    return <AccessDeniedPage appName="Adapt" email={email || undefined} onLogout={logout} />;
  }

  return <AuthContext.Provider value={{ email, logout, hasConsented }}>{children}</AuthContext.Provider>;
};
