function getReactAppEnv(env: string): string {
  const envName = `REACT_APP_${env}`;
  const value = process.env[envName];
  if (value === undefined) {
    throw new Error(`Missing config: ${envName}`);
  }
  return value;
}

export const API_BASE_URL = getReactAppEnv("API_BASE_URL");
export const AUTH_DOMAIN = getReactAppEnv("AUTH_DOMAIN");
export const AUTH_CLIENT_ID = getReactAppEnv("AUTH_CLIENT_ID");
export const AUTH_AUDIENCE = getReactAppEnv("AUTH_AUDIENCE");
export const AUTH_SCOPE = getReactAppEnv("AUTH_SCOPE");
export const AIS_BRANDING_BUCKET = getReactAppEnv("AIS_BRANDING_BUCKET");
