import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Link, Button } from "@material-ui/core";
import { useAuth } from "components/auth-wrapper/useAuth";
import { updateUserMetaData } from "utils/api";
import { Notification, showNotification } from "utils/notifications";
import useLogging from "hooks/useLogger";
import PDFViewer from "components/pdf-viewer/PDFViewer";
import styles from "./TermsAndConditionsView.module.scss";

const consentSuccessNotification: Notification = {
  severity: "info",
  message: "Your consent has been recorded",
};

const consentFailedNotification: Notification = {
  severity: "error",
  message: "Something went wrong and we were unable to record consent.",
};

const TermsAndConditionsView: React.FC = () => {
  const { hasConsented, email, logout, updateConsent } = useAuth();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [showTermsPdf, setShowTermsPdf] = useState<boolean>(false);

  const logger = useLogging();

  const onCloseDialogHandler = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    setIsDialogOpen(!hasConsented);
  }, [hasConsented]);

  const onUserConsentHandler = (value: boolean) => {
    updateConsent(value);
    if (!value) {
      //record consent and log out user, next login re-ask consent
      logout();
    } else {
      updateUserMetaData({ hasConsented: value })
        .then((res) => {
          if (res.userMetadata.consent.given) {
            onCloseDialogHandler();

            showNotification(consentSuccessNotification);
          }
        })
        .catch((err) => {
          logger.error("Failed to record consent: ", err);
          showNotification(consentFailedNotification);
        });
    }
    setIsDialogOpen(false);
  };

  return (
    <Dialog
      open={isDialogOpen}
      aria-labelledby="terms-dialog-title"
      data-testid="terms-and-conditions-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="terms-dialog-title">
        Australian Sports Commission ADAPT - Terms and Conditions of Use
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="terms-dialog-description" className={styles.dialogText}>
          The Australian Sports Commission (ASC) manages ADAPT and any athlete information that you may choose to upload
          to the service. The full terms and conditions located{" "}
          <Link onClick={() => setShowTermsPdf(!showTermsPdf)}>here</Link>.
          <br />
          By clicking accept, you <b>{email}</b> agree to these terms and conditions. Should you disagree, you will be
          logged out and unable to access ADAPT.
          <br />
          Should you have any queries, please contact the AIS team at{" "}
          <a href="mailto:AISdevelopers@ausport.gov.au">AISdevelopers@ausport.gov.au</a>.
        </DialogContentText>
        {showTermsPdf && <PDFViewer />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onUserConsentHandler(false)} color="primary">
          Decline
        </Button>
        <Button onClick={() => onUserConsentHandler(true)} color="primary">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsView;
