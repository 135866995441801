import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import Pagination from "@material-ui/lab/Pagination";
import styles from "./PDFViewer.module.scss";
import { AIS_BRANDING_BUCKET } from "settings";

// this is required to load  worker from pdf.js library when previewing pdf file
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer: React.FC = () => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  //@ts-expect-error unknown type
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const paginatioHandler = (e: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  return (
    <div className={styles.container}>
      <Pagination count={numPages} page={pageNumber} onChange={paginatioHandler} size="small" shape="rounded" />
      <Document
        file={{ url: `${AIS_BRANDING_BUCKET}/terms_and_conditions/ADAPT_terms_and_conditions.pdf` }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export default PDFViewer;
