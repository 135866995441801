import axios, { CancelToken } from "axios";
import { getAuthClient } from "./auth";
import { API_BASE_URL } from "../settings";

// TODO handle request errors
// TODO add interceptor to automatically add trailing slash

export interface ApiObject {
  readonly id: string;
}

export type Unsaved<T extends ApiObject> = Omit<T, "id">;

export interface Measure extends ApiObject {
  readonly label: string;
  readonly units: string;
  readonly decimalPlaces: number;
  readonly showPercentage: boolean;
  readonly regressionType: string;
}

export interface ApiProtocol extends ApiObject {
  name: string;
  timeStep: string;
  numSteps: number;
  controlled: string;
  measures: Array<string>;
}

export type ApiProtocolError = {
  [key in keyof ApiProtocol]?: string;
};
export interface Athlete extends ApiObject {
  firstName: string;
  surname: string;
  dateOfBirth: string;
}

export interface Tester extends ApiObject {
  firstName: string;
  surname: string;
}

export interface Step {
  [key: string]: string | null;
}

export interface ApiRegression {
  coefficients: Array<number> | null;
  rSquared: number | null;
}

export interface ApiAssessment extends ApiObject {
  testDate: string;
  temperature: number | null;
  pressure: number | null;
  humidity: number | null;
  steps: Array<Step>;
  athlete: string;
  tester: string | null;
  protocol: string;
  location: string | null;
  ergometer: string | null;
  metabolicCart: string | null;
  lactateAnalyser: string | null;
  comments: string | null;
  measures: Array<string>;
  athleteHeight: number | null;
  athleteWeight: number | null;
}

export interface AssessmentFilterParams {
  athlete?: string;
  protocol?: string;
}

export interface CompactAssessmentParams {
  search?: string;
  athlete?: string;
  protocol?: string;
  ordering?: string;
  limit?: number;
  offset?: number;
}

type UnsavedAssessment = Omit<Unsaved<ApiAssessment>, "lt1" | "lt2">;

export interface FullApiAssessment extends Omit<ApiAssessment, "athlete" | "tester" | "protocol"> {
  athlete: Athlete;
  tester: Tester | null;
  protocol: ApiProtocol;
  regressions: Record<string, ApiRegression>;
  lt1Calculated: number | null;
  lt1Measured: number | null;
  lt2At4mmol: number | null;
  lt2Dmax: number | null;
  lt2DmaxMod: number | null;
  maxControlled: number | null;
  athleteHeight: number | null;
  athleteWeight: number | null;
}

export interface Page<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface CompactApiAssessment {
  id: string;
  testDate: string;
  athlete: string;
  tester: string | null;
  protocol: string;
  lt1Calculated: number | null;
  lt1Measured: number | null;
  lt2Dmax: number | null;
  lt2DmaxMod: number | null;
  lt2At4mmol: number | null;
  maxControlled: number | null;
  athleteHeight: number | null;
  athleteWeight: number | null;
}

type UserConsent = {
  hasConsented: boolean;
};

export interface UserDetails {
  name: string;
  email: string;
  userMetadata: {
    consent: {
      given: boolean;
      timeStamp: string;
    };
  };
  userId: string;
}

export async function getApi() {
  try {
    const authClient = await getAuthClient();
    const token = await authClient.getTokenSilently();
    return axios.create({
      baseURL: API_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.error("Failed to load access token.");
    throw err;
  }
}

export async function getMeasures(cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.get<Array<Measure>>("measures/", { cancelToken });
  return response.data;
}

export async function getProtocols(cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.get<Array<ApiProtocol>>("protocols/", { cancelToken });
  return response.data;
}

export async function postProtocol(protocol: Unsaved<ApiProtocol>, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.post<ApiProtocol>("protocols/", protocol, { cancelToken });
  return response.data;
}

export async function putProtocol(protocol: ApiProtocol, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.put<ApiProtocol>(`protocols/${protocol.id}/`, protocol, { cancelToken });
  return response.data;
}

export async function getAthletes() {
  const api = await getApi();
  const response = await api.get<Array<Athlete>>("athletes/");
  return response.data;
}

export async function postAthlete(athlete: Unsaved<Athlete>, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.post<Athlete>("athletes/", athlete, { cancelToken });
  return response.data;
}

export async function getTesters() {
  const api = await getApi();
  const response = await api.get<Array<Tester>>("testers/");
  return response.data;
}

export async function postTester(tester: Unsaved<Tester>, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.post<Tester>("testers/", tester, { cancelToken });
  return response.data;
}

export async function getFullAssessment(assessmentId: string, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.get<FullApiAssessment>(`full-assessments/${assessmentId}/`, {
    cancelToken,
  });
  return response.data;
}

export async function getAssessmentInterpolation(
  assessmentId: string,
  measureId: string,
  measureValue: string,
  cancelToken?: CancelToken
) {
  const api = await getApi();
  const response = await api.get<Record<string, number>>(
    `assessments/${assessmentId}/interpolate/?measure_id=${measureId}&measure_value=${measureValue}`,
    {
      cancelToken,
    }
  );
  return response.data;
}

export async function getCompactAssessments(params: CompactAssessmentParams, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.get<Page<CompactApiAssessment>>("compact-assessments/", { params, cancelToken });
  return response.data;
}

export async function getFullAssessments(params: AssessmentFilterParams, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.get<Array<FullApiAssessment>>("full-assessments/", { params, cancelToken });
  return response.data;
}

export async function postAssessment(assessment: UnsavedAssessment, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.post<ApiAssessment>("assessments/", assessment, { cancelToken });
  return response.data;
}

export async function putAssessment(assessment: ApiAssessment, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.put<ApiAssessment>(`assessments/${assessment.id}/`, assessment, { cancelToken });
  return response.data;
}

/**
 * Delete an assessment by id.
 * @param assessmentId The assessments id
 * @param cancelToken Axios cancel token
 */
export async function deleteAssessment(assessmentId: string, cancelToken?: CancelToken) {
  const api = await getApi();
  return await api.delete<ApiAssessment>(`assessments/${assessmentId}/`, { cancelToken });
}

/**
 * Update user meta-data
 * Extracts consent condition from id_token and calls management api
 */

export async function updateUserMetaData(consent: UserConsent, cancelToken?: CancelToken) {
  const api = await getApi();
  const response = await api.post<UserDetails>(`terms-and-conditions/`, consent, { cancelToken });
  return response.data;
}
